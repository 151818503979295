<!--
 * @Author: kok-s0s
 * @Date: 2021-10-12 00:30:13
 * @LastEditors: kok-s0s
 * @LastEditTime: 2021-10-12 17:54:44
 * @Description: file content
-->
<template>
  <div id="app">
    <header>
      <h1>My Music</h1>
    </header>
    <main>
      <section class="player">
        <h2 class="song-title">
          {{ current.title }} -
          <span>{{ current.artist }}</span>
        </h2>
        <div class="controls">
          <button class="prev" @click="prevMusic">Prev</button>
          <button class="play" v-if="!isPlaying" @click="nowPlaying">Play</button>
          <button class="pause" v-else @click="clickPause">Pause</button>
          <button class="next" @click="nextMusic">Next</button>
        </div>
      </section>
      <section class="playList">
        <h3>The PlayList</h3>
        <button
          v-for="song in songs"
          :key="song.src"
          @click="nowPlaying(song)"
          :class="(song.src == current.src) ? 'song playing' : 'song'"
        >{{ song.title }} - {{ song.artist }}</button>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      current: {

      },
      index: 0,
      isPlaying: false,
      songs: [
        {
          title: '光棍',
          artist: '胡歌',
          src: require('./assets/胡歌-光棍.mp3')
        },
        {
          title: '忘记时间',
          artist: '胡歌',
          src: require('./assets/胡歌-忘记时间.mp3')
        },
        {
          title: '绿色',
          artist: '陈雪凝',
          src: require('./assets/陈雪凝-绿色.mp3')
        }
      ],
      player: new Audio()
    }
  },
  created() {
    this.current = this.songs[this.index];
    this.player.src = this.current.src;
  },
  methods: {
    nowPlaying(song) {
      if (typeof song.src != "undefined") {
        this.current = song;
        this.player.src = this.current.src;
      }

      this.player.play();
      this.isPlaying = true;
      this.player.addEventListener('ended', function () {
        this.index = (this.index + 1) % this.songs.length;
        this.current = this.songs[this.index];
        this.nowPlaying(this.current);
      }.bind(this));
    },

    clickPause() {
      this.player.pause();
      this.isPlaying = false;
    },

    prevMusic() {
      this.index = (this.index + 1) % this.songs.length;
      this.current = this.songs[this.index];
      this.nowPlaying(this.current);
    },

    nextMusic() {
      this.index = (this.index - 1 + this.songs.length) % this.songs.length;
      this.current = this.songs[this.index];
      this.nowPlaying(this.current);
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: #212121;
  color: #fff;
}

main {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 25px;
}

.song-title {
  color: #53565a;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.song-title span {
  font-weight: 400;
  font-style: initial;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;
}

button {
  appearance: none;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

.play,
.pause {
  font-size: 20px;
  font-weight: 700;
  padding: 15px 25px;
  margin: 0 15px;
  border-radius: 8px;
  color: #fff;
  background-color: #cc2e5d;
}

.prev,
.next {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 25px;
  margin: 0 15px;
  border-radius: 6px;
  color: #fff;
  background-color: #ff5858;
}

.playList {
  padding: 0px 30px;
}

.playList h3 {
  color: #212121;
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 30px;
  text-align: center;
}

.playList .song {
  display: block;
  width: 100%;
  padding: 15px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
}

.playList .song:hover {
  color: #ff5858;
}

.playList .song.playing {
  color: #fff;
  background-image: linear-gradient(to right, #cc2e5d, #ff5858);
}
</style>
